.elementAssetSectionContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  .borderOnly {
    border: 2px solid var(--grey-40);
    border-radius: 3px;
  }

  &:nth-child(odd) {
    margin-right: 24px;
  }

  .sectionLabel {
    font-size: 10px;
  }

  .assetContainer {
    max-height: 294px;
    overflow: auto;
  }
}

.headingWrapper {
  position: relative;
  left: -8px;
  margin-bottom: 24px;
}

.label {
  color: var(--prim-100);
  font-size: 10px;
}

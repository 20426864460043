.fieldContainer {
  display: flex;
  justify-content: center;
}

.field {
  height: 84px;
  width: 84px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 6px;
  transition: all 150ms;
  border-radius: 5px;
  position: relative;
  user-select: none;
  cursor: grab;

  &.disabled {
    opacity: 0.5;
  }

  &.highlighted {
    background: var(--grey-20);

    .assetsBadge {
      opacity: 1;
    }
  }

  .icon {
    display: flex;
    height: 40px;
    height: 40px;
    overflow: hidden;

    svg {
      padding-bottom: 8px;
    }
  }

  .label {
    position: relative;
    font-size: 12px;
    font-family: var(--font);
    color: var(--grey-70);
    font-weight: 500;
    overflow-wrap: break-word;
    max-width: 100%;
  }

  .assetsBadge {
    position: absolute;
    top: -4px;
    right: -4px;
    background-color: var(--prim-100);
    color: white;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    opacity: 0;
    font-size: 12px;
    transition: all 250ms;
    padding: 2px 6px;
    height: 20px;
    min-width: 20px;
  }
}

.assetsMenu {
  position: absolute;
  z-index: 10000;
  min-width: 200px;
  background: white;
  padding: 12px;
  box-shadow: 0 0 4px 0 #727b9944;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .title {
    font-size: smaller;
    text-transform: uppercase;
    font-weight: bolder;
    color: var(--grey-60);
  }
}

.assetSubMenu {
  min-width: 150px;
  background: var(--grey-90);
  color: white;
  padding: 12px;
  gap: 8px;
  box-shadow: 0 0 4px 0 #727b9944;
  border-radius: 6px;
  display: flex;
  flex-direction: column;

  .assetSubMenuItem {
    cursor: pointer;
  }
}

.resultsTable {
  .tableActionsBar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 16px;
    border-radius: 8px 8px 0 0;
    background-color: #fff;
    border: 1px solid var(--grey-40);

    .refreshSearch {
      display: flex;
      align-items: center;
      gap: 8px;

      .refreshButton {
        background-color: var(--grey-20) !important;
        margin: 0;
        height: 42px;
        padding: 10px;
      }
      .refreshButton:hover {
        background-color: var(--grey-50) !important;
      }
    }

    .filterAndCreateContainer {
      display: flex;

      .filterContainer {
        display: flex;
        align-items: center;
        gap: 8px;

        .filterButton {
          cursor: pointer;
          height: 40px;
          font-size: 1.3rem;
          display: flex;
          align-items: center;
          gap: 8px;
          color: var(--grey-90);

          svg {
            fill: var(--grey-90);
          }

          &.activeFilters {
            padding: 10px 5px;
            width: max-content;
          }
        }
      }
      .createButton {
        margin: 0 0 0 15px;
        height: 42px;
        padding: 10px 15px;
      }
    }
  }
}

.filterIndicator {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
  background-color: var(--grey-20);
  padding: 8px;
  height: 36px;
  border-radius: 8px;

  .filterIndicatorLabel {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 24ch;
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--grey-80);
  }
  .close {
    cursor: pointer;
    display: grid;
    place-content: center;
    color: var(--grey-70);

    & path {
      fill: currentColor;
      stroke-width: 1;
      stroke: currentColor;
    }

    &:hover {
      color: var(--prim-100);
    }
  }
}

.filterableTable {
  margin-top: 0;

  & th:first-child {
    border-top-left-radius: 0;
  }
  & th:last-child {
    border-top-right-radius: 0;
  }
}

.filterOverlay {
  position: relative;
  min-width: 240px;
  max-width: 480px;
  min-height: 180px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: -3px 2px 11px rgba(87, 92, 130, 0.32);

  .filterDialogClose {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 12px;
    right: 12px;
    height: 25px;
    width: 25px;
    border-radius: 100%;
    cursor: pointer;
    outline: none;
  }
  .filterContent {
    padding: 16px 16px 16px 16px;
  }
}
.filterTitle {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 16px;
}
.filterDialogButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  padding-top: 20px;
}

.filterButton {
  margin-bottom: 0;
}
.filterDialogButton {
  position: relative;
  color: white !important;
  font-family: var(--font);
  font-weight: 600;
  font-size: 1rem;
  outline: none !important;
  border: 0;
  min-width: 100px;
  z-index: 1;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 0;
  margin-left: 20px;
  border-radius: 6px;
  justify-content: center;
  padding: 15px 13px;
  background-color: var(--grey-90) !important;

  &.btnSecondary {
    color: var(--grey-90) !important;
    background-color: #fff !important;
    border: 1px solid var(--grey-90) !important;
    &:hover {
      background-color: var(--grey-10) !important;
    }
  }

  &:hover:not(.btnSecondary) {
    background-color: var(--grey-80) !important;
    color: white !important;
  }
}

/* ResultsHeader component */
.resultsHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;

  .summaryCard {
    margin-top: 20px;
    width: min-content;
    height: 80px;
    border: 2px solid var(--grey-10);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    background-color: white;

    .separator {
      height: 100%;
      width: 2px;
      background-color: var(--grey-10);
    }
  }
}
.activateButton {
  height: 35px;
  width: 130px;
  margin: auto 25px;
}

.activateButtonDisabled {
  color: var(--grey-70);
  cursor: not-allowed !important;
}

.summaryStatisticContainer {
  display: flex;
  align-items: center;
  padding: 16px 36px 16px 24px;

  .summaryStatisticColumn {
    display: flex;
    flex-direction: column;
    margin-left: 12px;

    .summaryStatisticLabel {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: var(--grey-70);
    }

    .summaryStatisticValue {
      font-weight: 700;
      font-size: 18px;
      color: var(--prim-100);
    }

    .resultsEnvSelector {
      margin-top: 5px;
    }
  }
}

/* Filter widget/dialog stuff */
.filterExpression {
  display: flex;
  align-items: center;
  gap: 8px;

  &.field {
    width: 448px;
  }

  .dateInput {
    border: 1px solid var(--grey-border);
    border-radius: 4px;
    height: 32px;
  }
}

.datePicker {
  z-index: 100;
}

.folderList {
  display: flex;
  flex-direction: column;
  gap: 6px;
  background: white;
  padding: 12px;
  border: 1px solid var(--grey-40);
  border-top: none;
}

.folderItem {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  padding-right: 8px;
  border: solid 1px #dbdfe8;
  background: #f4f5f8;
  color: #414859;
  border-radius: 8px;
  cursor: pointer;
  height: 52px;

  &:hover {
    background: white;
  }

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.folderLabel {
  padding: 0;
  margin: 0;
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  margin-left: 12px;
}

.folderDetails {
  padding: 0;
  margin: 0;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: hsla(221, 12%, 48%, 1);
  margin-left: 12px;
}

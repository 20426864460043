.bulkInviteButton {
  border: 2px solid var(--prim-100);
  color: var(--prim-100);
  width: max-content;
  margin: 20px 0 10px 0;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
}

.bulkUploadSubtitle {
  font-size: 15px;
  color: var(--grey-80);
  margin-top: 20px;
}

.bulkUploadValueToggle {
  margin-top: 10px;
}

.bulkUploadInput {
  width: 100%;
  border-width: 1px;
  border-radius: 4px;
  border-color: var(--grey-40);
  margin-top: 20px;
  padding: 5px 10px;
  color: var(--grey-80);
}

.bulkUploadTrigger {
  width: 75px;
  height: 25px;
  background-color: var(--prim-10);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--prim-100);
  font-size: 12px;
  border-radius: 5px;
}

.bulkUploadTriggerDisabled {
  color: var(--grey-70);
  background-color: var(--grey-20);
}

.themeSwapContainer {
  margin-top: 20px;

  .themeSwapTypeContainer {
    display: grid;
    grid-template-columns: 380px 70px 380px;
    margin-top: 20px;
    border-bottom: 4px solid var(--grey-20);

    .typeLabel {
      grid-row: 1/2;
      grid-column: 1/2;
      font-size: 14px;
      color: black;
      font-weight: 700;
    }

    .curThemeLabel {
      grid-row: 2/3;
      grid-column: 1/2;
      color: var(--grey-70);
      font-size: 12px;
      font-weight: 400;
    }

    .newThemeLabel {
      grid-row: 2/3;
      grid-column: 3/4;
      color: var(--grey-70);
      font-size: 12px;
      font-weight: 400;
    }

    .arrowIcon {
      width: 100%;
      align-self: center;

      svg {
        stroke: var(--grey-80);
      }
    }

    .elementLabelContainer {
      width: 100%;
      height: 100%;
      border: 2px solid var(--grey-30);
      border-radius: 5px;
      position: relative;

      .elementContainerLabel {
        position: absolute;
        top: 10px;
        left: 10px;
        background-color: white;
        z-index: 1;
        height: 20px;
        padding: 0 10px 20px 0;
        border-radius: 5px;
        color: var(--grey-70);

        &.primary {
          color: var(--prim-100);
        }
      }

      .elementContainer {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-y: auto;
        padding: 15px 10px;
      }

      .swapToggle {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: var(--grey-30);
        position: absolute;
        top: calc(50% - 12px);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 2px;

        svg {
          stroke: black;
        }

        &.left {
          left: -12px;
          transform: rotate(90deg);
        }

        &.right {
          right: -12px;
          transform: rotate(270deg);
        }
      }
    }
  }
}

.deleteThemeList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 50px;
}

.fontUploadButton {
  border: 2px solid var(--prim-100);
  color: var(--prim-100);
  width: max-content;
  margin: 20px 0 10px 0;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
}

.fontUploadSection {
  padding: 20px 0;
  border-bottom: 1px solid var(--grey-30);
  width: 100%;

  .fontFamilyInput {
    width: 150px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .label {
      padding-top: 0;
    }

    .delete {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: var(--grey-20);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .fileNameSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;

    .icon {
      width: 32px;
      height: 32px;
      border: 1px solid var(--grey-30);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
  }

  .fontStylesSection {
    display: flex;
    flex-direction: row;

    .styleSection {
      width: 150px;
      margin-right: 20px;

      .styleLabel {
        margin-bottom: 5px;
      }
    }
  }
}

.inviteUserLabel {
  color: var(--grey-90);
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: medium;
}

.inviteOptionalAttributesTitle {
  color: var(--prim-100);
  margin: 0;
  margin-left: 5px;
  padding: 0;
  font-size: smaller;
}

.inviteOptionalAttributesHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  margin-top: -10px;
}

.inviteOptionalAttributesExpandIndicator {
  margin-left: -10px !important;
  color: #e2626e;
  padding: 0;
  font-weight: bold;
  font-size: smaller;
}

.invitesContainer {
  width: 100%;
}

.submissionSummary {
  display: flex;
  flex-direction: column;
  margin-top: -6px;

  .subTitle {
    font-weight: 400;
    font-size: 12px;
  }

  .submissionsList {
    padding-top: 12px;
    height: 240px;
    overflow-x: hidden;
    overflow-y: auto;

    .submissionsListTable {
      width: 100%;
      table-layout: fixed;
      border: collapse;
      border-top: 1px solid var(--grey-20);
    }

    .count {
      width: 20%;
    }

    .cell {
      line-height: 36px;
      border-bottom: 1px solid var(--grey-20);
    }

    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.submissionsOverage {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: 400;
  font-size: 16px;
}

.planUpgrade {
  font-weight: 600;
  font-size: 14px;

  .title {
    margin-top: 20px;
  }

  .planCost {
    line-height: 24px;
  }

  .prorated {
    color: var(--grey-70);
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    margin-top: 4px;
  }

  .warning {
    display: flex;
    align-items: center;
    gap: 6px;
    color: var(--error);
  }
}

.paymentMethod {
  margin-top: 16px;

  .label {
    display: flex;
    align-items: baseline;
    gap: 8px;
    color: var(--grey-80);
    font-weight: 500;
  }

  .validation {
    color: var(--error);
  }
}

.publishErrorWarningList {
  max-height: 500px;
  overflow-y: auto;
  li {
    margin-bottom: 6px;
  }
  .publishErrorWarning {
    display: flex;
    flex-direction: column;

    .firstLevelItem {
      font-weight: 600;
    }
  }
}

.downloadCSVFilter {
  margin-top: 30px;
  margin-bottom: 30px;

  .dateInput {
    margin-left: 10px;
    border: 1px solid grey;
    border-radius: 4px;
  }

  &.end {
    margin-bottom: 200px;

    .dateInput {
      margin-left: 17px;
    }
  }
}
.ruleCreateActions {
  margin-top: 100px;
}

.inviteTeamEmailRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  .inviteLabel {
    width: 70px;
    margin-right: 10px;
  }

  .inviteLabelWide {
    display: flex;
    width: 150px;
  }
}

.createDocumentModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  font-size: 16px;
  padding: 16px;

  .error {
    color: var(--error);
  }

  .selectedFile {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #e7e7e7;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .close {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      padding: 5px;
      cursor: pointer;
    }
  }
  .chooseButton {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.collaborationTaskDetails {
  font-size: 18px;
  padding: 16px 0;

  .collaboratorDetail {
    display: flex;
    gap: 8px;

    .dataLabel {
      font-weight: bold;
      min-width: 28%;
    }
  }
}

.labelContainer {
  display: flex;
  width: max-content;
}

.questionGroupSection {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px -20px;
  border-top: 1px solid var(--grey-30);
  width: 100%;

  .actionContainer {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .actionButton {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: var(--grey-20);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      &.fill {
        fill: currentColor !important;
      }
      &.flip {
        transform: rotate(180deg);
      }
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    .label {
      padding-top: 0;
      text-transform: capitalize;
    }
  }

  .controlsSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    min-height: 45px;

    .controlLabel {
      display: flex;
      width: 160px;
    }

    .controlField {
      width: 100%;
      min-height: 45px;
    }
  }
}

.warningContent {
  & > .title {
    font-size: 1.1rem;
  }

  & > .content {
    margin-top: 20px;

    & > .warningItem {
      margin-bottom: 10px;
      padding: 15px;
      border-radius: 10px;
      background-color: var(--grey-10);
      color: var(--grey-80);

      & > .title {
        text-transform: uppercase;
        font-size: 0.85rem;
        font-weight: 700;
        margin-bottom: 5px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.card {
  padding: 15px;
  border-radius: 10px;
  background-color: var(--grey-10);
  color: var(--grey-80);

  & .title {
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 0 !important;
  }

  &.warning {
    background-color: #fffbdc;
    border: 1px solid #fbea4c;
  }

  &.white {
    background-color: white;
    border: 2px solid var(--grey-30);
  }
}

.keyValuePairs {
  width: 100%;

  .keyValueRow {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .keyValuePair {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: nowrap;

    margin-bottom: 8px;

    & > .key {
      width: 115px;
      min-height: 40px;
      border-radius: 4px;
      background-color: var(--grey-10);
      padding: 6px 12px;
      font-weight: 600;
      color: var(--grey-80);
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      flex-shrink: 0;
    }

    & > .option {
      width: 70px;
      min-height: 40px;
      flex-shrink: 0;

      select {
        width: 100%;
        height: 40px;
      }
    }

    & > .value {
      flex: 1;
      min-width: 0;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:not(.autoHeight) input,
      &:not(.autoHeight) select {
        min-height: 40px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .switch {
        margin-top: 3px;
      }

      .multiFieldValueContainer {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    & > .actions {
      display: flex;
      align-self: stretch;
      align-items: center;
    }
  }
}

.dashedButton {
  display: flex;
  outline: none;
  border: 1px dashed var(--grey-50);
  border-radius: 6px;
  background-color: var(--grey-10);
  color: var(--grey-80);
  font-weight: 600;
  font-size: 1rem;
  padding: 6px 16px;
  align-items: center;
  gap: 5px;
  transition: background-color 0.1s ease-in-out;

  & > .icon {
    color: var(--grey-80);
  }

  &:hover {
    background-color: var(--grey-20);
  }
}

.advancedSectionHeader {
  padding: 4px 0 0 0 !important;
}

.advancedSectionTitle {
  font-weight: 500 !important;
  color: var(--grey-70) !important;
  font-size: 0.9rem !important;
  text-transform: uppercase;
}

.advancedSectionContent {
  padding: 0 !important;
  padding-top: 8px !important;
}

.advancedSectionExpandIndicator {
  color: var(--grey-70) !important;
}

.entitiesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > .entity {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.action {
  svg {
    transition: stroke 0.1s ease-in-out;
  }

  &:hover {
    cursor: pointer;

    svg {
      stroke: var(--prim-100) !important;
      color: var(--prim-100) !important;
    }
  }
}

.multiFieldControl {
  border-color: var(--grey-border) !important;
  border-radius: var(--input-radius) !important;
  min-height: 38px !important;

  &:hover {
    border-color: var(--grey-border) !important;
  }
}

.multiFieldControlFocused {
  color: var(--body-color) !important;
  background-color: white !important;
  border-color: #86b7fe !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;

  &:hover {
    border-color: #86b7fe !important;
  }
}

.multiFieldValueContainer {
  gap: 5px;
  padding: 0.375rem 0.75rem !important;
  min-height: 38px !important;
}

.multiFieldValue {
  margin: 0 !important;
  padding: 4px 10px !important;
  gap: 3px;
  align-items: flex-start;
  height: fit-content;
}

.multiFieldValueLabel {
  line-height: 1rem;
  padding-top: 2px;
  display: flex;
  align-items: center;
}

.multiFieldInput {
  padding: 0 !important;
  margin: 0 !important;

  & > input {
    font-size: 1rem !important;
    font-weight: 400 !important;

    min-height: 25px !important;
    height: 25px !important;
    padding: 0 !important;
  }
}

.multiFieldControlDisabled {
  background-color: hsl(0, 0%, 97%) !important;
}

.multiFieldPlaceholder {
  font-size: 1rem;
  font-weight: 400 !important;
}

.logicPage {
  padding: 20px 10px 0 10px;
  position: relative;
  height: 100%;

  .ctaHeader {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
}

.ruleEnable {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  gap: 8px;
}

.ruleValidCell {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  gap: 8px;
}

.ruleMoveUpDownCell {
  display: flex;
  align-items: center;
  gap: 8px;
}

.items {
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  flex-wrap: wrap;

  .item {
    background-color: #eee;
    border-radius: 6px;
    padding: 2px 4px;
    white-space: nowrap;
  }
}

.ruleAction {
  cursor: pointer;

  &:hover {
    stroke: var(--prim-100);

    &.filled {
      fill: var(--prim-100);
    }
  }
}

.otherFieldsSwitch {
  display: flex;
  align-items: center;
  gap: 8px;
}

.globalConfigButton {
  position: absolute;
  left: 245px;
  top: 40px;
  z-index: 10;

  &.emptyState {
    left: unset;
    right: 20px;
    top: 20px;
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 6px;
  transition: all 250ms;
  border-radius: 4px;
  justify-content: space-between;
  user-select: none;
  cursor: grab;

  .iconAndLabel {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .overflowIcon {
    visibility: hidden;
    cursor: pointer;
  }

  &:hover {
    background-color: var(--grey-20);

    .overflowIcon {
      visibility: visible;
    }
  }

  .itemIcon {
    width: 20px;
    height: 20px;
  }

  .assetName {
    outline: none;
    background: transparent;

    height: 20px;
    padding: 0 4px;

    &:disabled {
      cursor: inherit;
    }

    &:focus {
      background-color: white;
    }
  }
  .label {
    position: relative;
    font-size: 12px;
    font-family: var(--font);
    color: var(--grey-80);
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    max-width: 80px;
    display: flex;
  }
}

.item.menuStyling {
  padding: 8px;
}
.assetName.menuStyling {
  width: min-content;
}
.itemIcon.listStyling {
  display: flex;
  width: 22px;
  height: 22px;
  min-width: 30px;
  margin-right: 2px;
}

.assetSubMenu {
  min-width: 150px;
  background: var(--grey-90);
  color: white;
  padding: 12px;
  gap: 8px;
  box-shadow: 0 0 4px 0 #727b9944;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  z-index: 10000;

  .assetSubMenuItem {
    cursor: pointer;
    display: flex;
    gap: 14px;
    align-items: center;
    transition: all 100ms;
    font-family: var(--font);
    font-weight: 300;
    padding: 4px 8px 4px 4px;

    &:hover {
      color: var(--prim-100);

      svg * {
        stroke: var(--prim-100);
      }
    }
  }
}

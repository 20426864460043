.aiExtractionsListPage {
  padding: 0 35px;

  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 35px;

    & > .title {
      display: flex;
      height: 32px;
      align-items: center;
      gap: 16px;

      & > h3 {
        font-size: 1.5rem;
        font-weight: 700;
        font-family: var(--font);
        margin-bottom: 0;
      }
    }

    & > .actions {
      & > button {
        margin: 0 !important;
      }
    }
  }

  & > .section {
    & > .label {
      font-weight: 500;
      font-size: 1.6em;
    }
  }
}

.status {
  border-radius: 4px;
  padding: 5px 10px;
  background-color: var(--prim-100);
  width: fit-content;
  color: #fff;
  font-weight: 500 !important;
  font-size: 0.8rem;
  text-transform: uppercase;

  &.error {
    background-color: var(--prim-100);
  }

  &.approved {
    background-color: var(--system-valid);
  }

  &.invalid {
    background-color: var(--grey-70);
  }

  &.pending {
    background-color: #ffa500;
  }

  &.processing {
    background-color: var(--grey-40);
    color: var(--grey-80);
  }
}

$field-width: 250px;

.attribute {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .attributeLabel {
    -webkit-appearance: none;
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: var(--input-radius);
    color: var(--body-color);
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;

    max-width: 160px;
    margin-right: 7px;
    background-color: var(--grey-10) !important;
    border: none;
    height: 40px;
    border-radius: 5px;
    font-weight: 300;
    color: var(--grey-80);
    &[disabled] {
      color: var(--grey-70);
    }
  }

  .attributeField {
    -webkit-appearance: none;
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: var(--input-radius);
    color: var(--body-color);
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;

    width: $field-width;
    height: 40px;
    border-radius: 5px;
    color: var(--grey-80);
    font-weight: 300;
    margin-right: 7px;
  }

  .attributeField:disabled {
    background-color: #efefef;
    opacity: 1;
  }

  .attributeField::placeholder {
    color: var(--grey-60);
    font-size: small;
    font-weight: 300;
  }

  .attributeFieldDropDown {
    width: $field-width;
    height: 40px;
  }

  .attributeFieldDropDownMulti {
    margin: 0;
    padding: 0;
    width: $field-width;
    height: 40px;
  }
}

.ruleCodeContainer {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  overflow: hidden;
  height: 100%;
  border-radius: inherit;

  &.maximized {
    margin: 0;
  }

  .ruleCodeEditorContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    border-radius: 2px;

    .ruleCodeSpacer {
      background-color: #fff;
      border-left-style: solid;
      border-left-color: #f4f5f8;
      height: 10px;

      &.readOnlyText {
        height: auto;
        padding-top: 22px;
        padding-bottom: 22px;
        padding-left: 10px;
        max-width: 80%;
      }
    }

    .ruleCodeHeader {
      position: absolute;
      top: -40px;
      right: 0;
      width: fit-content;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: rgba(0, 0, 0, 0);
      border-radius: 14px 14px 0 0;
      z-index: 10;

      & h4 {
        font-size: 0.875em;
        font-weight: 600;
        margin: 0;
      }

      .ruleEditCodeButton {
        outline: none;
        border: none;
        background-color: var(--grey-20);
        padding: 7px 14px;
        border-radius: 6px;
        font-size: 0.85em;
        transition: background-color 0.1s ease-in-out;

        &:hover {
          background-color: var(--grey-40);
        }
      }

      .ruleCodeButton {
        display: flex;
        font-size: 14px;
        gap: 5px;
        align-items: center;
        outline: none;
        border: none;
        border-radius: 4px;
        background-color: var(--grey-20);
        padding: 4px 8px;
      }

      .minMaximize {
        border: none;
      }

      .ruleCodeButtons {
        display: flex;
        gap: 8px;
      }
    }

    .ruleCodeEditor {
      position: relative;
      min-height: 200px;
      flex: 1;

      .codeEditor {
        background-color: #fff;
      }

      & div[class='ace_active-line'] {
        background: rgb(239, 239, 239) !important;
        background: linear-gradient(
          90deg,
          rgba(239, 239, 239, 1) 0%,
          rgba(255, 255, 255, 1) 100%
        ) !important;
      }
    }
  }
}

.errorLineMarker {
  position: absolute;
  background-color: var(--prim-10);
}

.ruleValidations {
  max-height: 250px;
  overflow-y: auto;
  border-top: 1px solid #e0e0e0;
  background-color: #fff;
  border-radius: 2px;
  font-size: 16px;

  .ruleValidationsHeader {
    padding: 16px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fafafb;

    & h3 {
      font-size: 0.875em;
      font-weight: 600;
      margin: 0;
    }
  }

  .ruleValidationsError {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--error);
    background-color: var(--prim-10);
    border-bottom: 1px solid #e0e0e0;
    padding: 8px;
    font-size: 0.875em;
  }
}

.aiExtractionsListPage {
  padding: 0 30px;
}

.modalInput {
  margin-bottom: 20px;
  min-height: 45px;

  ::placeholder {
    color: var(--grey-70);
  }
}

.labelContainer {
  display: flex;
  width: max-content;
}

.switch {
  margin-bottom: 20px;
}

.addGroupButton {
  border: 2px solid var(--prim-100);
  color: var(--prim-100);
  width: max-content;
  margin: 0 0 60px 0;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
}

.error {
  background-color: var(--prim-10);
  border: 1px solid var(--prim-100);
  padding: 10px;
  border-radius: 6px;

  display: flex;
  gap: 10px;

  align-items: center;

  & > .icon {
    margin: 0 5px;

    & > svg path {
      fill: var(--prim-100);
    }
  }

  & > .content {
    flex: 1;
    color: var(--grey-80);

    & > .title {
      font-weight: bold;
    }

    & > .message {
      font-size: 0.9rem;
    }
  }
}

.fieldsList {
  margin-bottom: 1rem;
}

.listEmpty {
  color: var(--grey-70);
  padding-block: 1rem;
}

.fieldListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-block: 0.5rem;
}

.fieldInfo {
  display: flex;
  gap: 0.5rem;
  padding: 0.75rem;

  align-items: center;
}

.listHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 0.75rem;
}

.fieldActions {
  display: flex;
  gap: 0.75rem;
}

.formActions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.fieldLabel {
  font-weight: 500;
  max-width: 42ch;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mergeFilterHeader {
  padding: 0.2rem 0 0.2rem !important;
}

.mergeFilterTitle {
  color: var(--grey-70);
  font-weight: 400 !important;
  font-size: 16px;
}

.mergeFilterSection {
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}

.mergeFilterIndicator {
  svg {
    stroke: var(--grey-70);
  }
}
